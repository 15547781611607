import appCategories from './categories';

const domain = process.env.NEXT_PUBLIC__PLATFORM_URL || 'https://benzo.rs';

export const defaultSeo = {
    name: 'Benzo',
    title: 'Benzo - Description about the project',
    description: 'Description about the project',
    category: 'Project category',
    pageUrl: domain,
    logoUrl: `${domain}/logo.png`,
    logoWidth: 256,
    logoHeight: 102,
    imageUrl: `${domain}/facebook-cover.png`,
    imageWidth: 1200,
    imageHeight: 630,
    social: ['https://www.instagram.com/benzo'],
    twitter: undefined,
    alternateName: ['benzo', 'benzo.rs', 'benzo project'],
    contactEmail: undefined,
    rssFeed: [{ url: '/rss/article-rss.xml' }],
    favicons: {
        icon: [
            { sizes: '32x32', url: '/favicon-32x32.png', type: 'image/png' },
            { sizes: '16x16', url: '/favicon-16x16.png', type: 'image/png' },
        ],
        apple: [{ url: '/apple-touch-icon.png' }],
    },
};

export const blogAuthor = {
    name: 'Benzo Team',
    image: `${domain}/logo192.png`,
};

export const footerLinks = [
    {
        title: 'Company',
        links: [
            { title: 'About Us', url: '/about' },
            { title: 'Contact Us', url: '/contact' },
        ],
    },
    {
        title: 'Resources',
        links: [{ title: 'Blog', url: '/blog' }],
    },
    {
        title: 'Subjects',
        links: appCategories.subjects.map((subj: any) => ({
            title: subj.subject,
            url: `/${subj.urlSlug}/blog`,
        })),
    },
];
