'use client';

import * as React from 'react';
import { Button, Link, TopMenu } from '@repo/ui';
import Image from 'next/image';
// import TopMenuRightSide from '@/components/organisms/TopMenu/TopMenuRightSide';
// import TopMenuPricingButton from '@/components/organisms/TopMenu/TopMenuPricingButton';
// import TopMenuPaymentNeeded from '@/components/organisms/TopMenu/TopMenuPaymentNeeded';
import appCategories from '@/constants/categories';
// import { useUserStore } from '@/store/mutable-data/user/UserStoreContext';
import { defaultSeo } from '@/constants/seo-constants';

const TopMenuWrapper = () => {
    // const { user } = useUserStore();
    // const paymentNeeded = user.paymentStatus === 'PAYMENT_NEEDED';

    return (
        <TopMenu
            companyName={defaultSeo.name}
            Image={Image}
            // hasBanner={paymentNeeded}
            appCategories={appCategories}
            // TopMenuRightSide={TopMenuRightSide}
            // TopMenuBanner={TopMenuPaymentNeeded}
            // TopMenuLeftSideAddition={TopMenuPricingButton}
            customLeftSide={
                <div>
                    <Link style={{ color: '#fff' }} to="/blog">
                        <Button variant="text">Blog</Button>
                    </Link>
                </div>
            }
        />
    );
};

export default TopMenuWrapper;
