import type { ArticlePrefix } from '@repo/utils/articleUtils';

const subjects = [
    {
        id: 1,
        imageUrl:
            'https://static.code-hl.com/resources/categories/Artificial+Intelligence+(AI).png',
        urlSlug: 'template',
        subject: 'Template category (TP)',
        description: 'Description about the category',
    },
];

export const availableTabs: ArticlePrefix[] = ['blog'];

const appCategories: any = {
    subjects,
};

export default appCategories;
